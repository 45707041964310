<template>
  <div class="grid">
		<div class="col-12">
            <div class="card">
                <Toast/>
                <Message ref="message" severity="error" :closable="true" v-if="errors.length">{{errors}}</Message>

                <Fieldset legend="Filter" :toggleable="true" :collapsed="true" class="mb-3">
                    <div class="p-fluid">
                        <div class="formgrid grid">
                            <div class="field col-12 md:col-6 lg:col-3">
                                <label for="period">Periode</label>
                                <Calendar selectionMode="range" v-tooltip.top="'Maksimal 30 hari'" :manualInput="false" v-model="period" :maxDate="maxDate" dateFormat="dd/mm/yy" icon="pi pi-calendar" :showIcon="true"/>
                            </div>
                        </div>
                    </div>
                    <Button :loading="loading" label="Filter" icon="pi pi-search" class="p-button-warning mr-2 my-1" @click="getDataTable" />
                </Fieldset>

                <DataTable :value="dataTable" responsiveLayout="scroll" :loading="loading" dataKey="se_id" @sort="onSort($event)" :resizableColumns="true">
                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <div>
                                <Button :loading="loadingExcel" label="Export Excel" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('xlsx')" />
                                <Button :loading="loadingCsv" label="Export CSV" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('csv')" />
                            </div>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="search" placeholder="Cari..." @keyup.enter="getDataTable" />
                            </span>
                        </div>
                    </template>
                    <Column field="number" header="No." :style="{width:'20px'}" >
                        <template #body="slotProps">
                        {{ slotProps.data.number }}
                        </template>
                    </Column>
                    <Column field="name" header="Nama">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.v000_user_hp_login !== null"> 
                                {{slotProps.data.v000_user_hp_login.name}}
                            </div>
                        </template>
                    </Column>
                    <Column field="username" header="Username">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.v000_user_hp_login !== null"> 
                                {{slotProps.data.v000_user_hp_login.username}}
                            </div>
                        </template>
                    </Column>
                    <Column field="level_name" header="Level">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.v000_user_hp_login !== null"> 
                                {{slotProps.data.v000_user_hp_login.level_name}}
                            </div>
                        </template>
                    </Column>
                    <Column field="se_in_time" header="Time SE In">
                        <template #body="slotProps">
                            {{slotProps.data.se_in_time}}
                        </template>
                    </Column>
                    <Column field="se_in_location" header="Lokasi SE In">
                        <template #body="slotProps">
                            {{slotProps.data.se_in_location}}
                        </template>
                    </Column>
                    <Column field="se_in_koordinat" header="Koordinat SE In">
                        <template #body="slotProps">
                            <a :href="'https://maps.google.com/maps?q=loc:' + slotProps.data.se_in_lat + ',' + slotProps.data.se_in_lng" target="_blank"> {{ slotProps.data.se_in_lat }}, {{ slotProps.data.se_in_lng }}</a>
                        </template>
                    </Column>
                    <Column field="se_in_maps" header="Map SE In">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.se_in_lat && slotProps.data.se_in_lng">
                                <Map :id="slotProps.data.number" :lat="slotProps.data.se_in_lat" :lng="slotProps.data.se_in_lng" />
                            </div>
                        </template>
                    </Column>
                    <Column field="se_in_gps_address" header="Alamat GPS SE In">
                        <template #body="slotProps">
                            {{slotProps.data.se_in_gps_address}}
                        </template>
                    </Column>
                    <Column field="foto" header="Photo SE In">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.foto">
                                <Image :src="slotProps.data.foto" :alt="slotProps.data.foto" width="100" preview />
                            </div>
                            <div v-else>
                                <Image src="no_image.jpg" alt="No Image" width="100" preview />
                            </div>
                        </template>
                    </Column>
                    <Column field="se_in_note" header="Note SE In">
                        <template #body="slotProps">
                            {{slotProps.data.se_in_note}}
                        </template>
                    </Column>
                    <Column field="se_out_time" header="Time SE Out">
                        <template #body="slotProps">
                            {{slotProps.data.se_out_time}}
                        </template>
                    </Column>
                    <Column field="se_out_location" header="Lokasi SE Out">
                        <template #body="slotProps">
                            {{slotProps.data.se_out_location}}
                        </template>
                    </Column>
                    <Column field="se_out_koordinat" header="Koordinat SE Out">
                        <template #body="slotProps">
                            <a :href="'https://maps.google.com/maps?q=loc:' + slotProps.data.se_out_lat + ',' + slotProps.data.se_out_lng" target="_blank"> {{ slotProps.data.se_out_lat }}, {{ slotProps.data.se_out_lng }}</a>
                        </template>
                    </Column>
                    <Column field="se_out_maps" header="Map SE Out">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.se_out_lat && slotProps.data.se_out_lng">
                                <Map :id="slotProps.data.number" :lat="slotProps.data.se_out_lat" :lng="slotProps.data.se_out_lng" />
                            </div>
                        </template>
                    </Column>
                    <Column field="se_out_gps_address" header="Alamat GPS SE Out">
                        <template #body="slotProps">
                            {{slotProps.data.se_out_gps_address}}
                        </template>
                    </Column>
                    <Column field="se_out_photo" header="Photo SE Out">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.se_out_photo">
                                <Image :src="slotProps.data.se_out_photo" :alt="slotProps.data.se_out_photo" width="100" preview />
                            </div>
                            <div v-else>
                                <Image src="no_image.jpg" alt="No Image" width="100" preview />
                            </div>
                        </template>
                    </Column>
                    <Column field="se_out_note" header="Note SE Out">
                        <template #body="slotProps">
                            {{slotProps.data.se_out_note}}
                        </template>
                    </Column>
                    <Column field="se_out_type" header="Tipe SE Out">
                        <template #body="slotProps">
                            {{slotProps.data.se_out_type}}
                        </template>
                    </Column>
                    <Column field="durasi" header="Durasi">
                        <template #body="slotProps">
                            {{slotProps.data.durasi}}
                        </template>
                    </Column>
                    <!-- <Column field="se_in_date" header="SE In Date">
                        <template #body="slotProps">
                            {{slotProps.data.se_in_date}}
                        </template>
                    </Column>
                    <Column field="time_login" header="Time Login">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.v000_user_hp_login !== null"> 
                                {{slotProps.data.v000_user_hp_login.time_login}}
                            </div>
                        </template>
                    </Column>
                    <Column field="time_logout" header="Time Logout">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.v000_user_hp_login !== null"> 
                                {{slotProps.data.v000_user_hp_login.time_logout}}
                            </div>
                        </template>
                    </Column>
                    <Column field="alamat_ip" header="Alamat IP">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.v000_user_hp_login !== null"> 
                                {{slotProps.data.v000_user_hp_login.alamat_ip}}
                            </div>
                        </template>
                    </Column>
                    <Column field="platform" header="Plaform">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.v000_user_hp_login !== null"> 
                                {{slotProps.data.v000_user_hp_login.platform}}
                            </div>
                        </template>
                    </Column>
                    <Column field="isp_name" header="ISP Name">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.v000_user_hp_login !== null"> 
                                {{slotProps.data.v000_user_hp_login.isp_name}}
                            </div>
                        </template>
                    </Column>
                    <Column field="isp_country" header="ISP Country">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.v000_user_hp_login !== null"> 
                                {{slotProps.data.v000_user_hp_login.isp_country}}
                            </div>
                        </template>
                    </Column>
                    <Column field="isp_region" header="ISP Region">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.v000_user_hp_login !== null"> 
                                {{slotProps.data.v000_user_hp_login.isp_region}}
                            </div>
                        </template>
                    </Column>
                    <Column field="isp_city" header="ISP City">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.v000_user_hp_login !== null"> 
                                {{slotProps.data.v000_user_hp_login.isp_city}}
                            </div>
                        </template>
                    </Column>
                    <Column field="device_name" header="Device Name">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.v000_user_hp_login !== null"> 
                                {{slotProps.data.v000_user_hp_login.device_name}}
                            </div>
                        </template>
                    </Column>
                    <Column field="device_model" header="Device Model">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.v000_user_hp_login !== null"> 
                                {{slotProps.data.v000_user_hp_login.device_model}}
                            </div>
                        </template>
                    </Column>
                    <Column field="device_platform" header="Device Platform">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.v000_user_hp_login !== null"> 
                                {{slotProps.data.v000_user_hp_login.device_platform}}
                            </div>
                        </template>
                    </Column>
                    <Column field="device_os" header="Device OS">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.v000_user_hp_login !== null"> 
                                {{slotProps.data.v000_user_hp_login.device_os}}
                            </div>
                        </template>
                    </Column>
                    <Column field="device_os_version" header="Device OS Version">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.v000_user_hp_login !== null"> 
                                {{slotProps.data.v000_user_hp_login.device_os_version}}
                            </div>
                        </template>
                    </Column>
                    <Column field="device_manufacturer" header="Device Manufacture">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.v000_user_hp_login !== null"> 
                                {{slotProps.data.v000_user_hp_login.device_manufacturer}}
                            </div>
                        </template>
                    </Column> -->
                    <template #empty>
                        No data found.
                    </template>
                    <template #loading>
                        Loading data. Please wait.
                    </template>
                    <template #footer>
                        In total there are {{totalItemsCount ? totalItemsCount : 0 }} data.
                    </template>
                </DataTable>

                <Paginator v-model:rows="rows" v-model:first="offset" :totalRecords="totalItemsCount" :rowsPerPageOptions="[10,20,30]">
                    <template>
                        {{totalItemsCount}}
                    </template>
                </Paginator>
            </div>
      </div>
  </div>
</template>

<script>
import moment from 'moment';
import Map from '../../components/Map.vue';
import { mapGetters } from "vuex";
import { numberingDatatable } from '../../utils/helpers';

export default {
    components: {
        'Map': Map,
    },
    data() {
        return {
            // loading
            loading: false,
            loadingExcel: false,
            loadingCsv: false,
            
            // filter
            period: [new Date(), new Date()],
            maxDate: null,
            filters: {
            },

            //datatables
            data: null,
            dataTable: null,
            rows: 10,
            search: null,
            offset: null,
            field: null,
            sort: null,
            page: null,
            totalItemsCount: 0,
        }
    },
    mounted() {
        this.getDataTable();
        this.$store.commit('setErrors', {});
        this.$store.commit('setErrorAdd', {});
        this.$store.commit('setErrorEdit', {});
    },
    watch: {
        offset() {
            this.getDataTable();
        },
        rows() {
            this.getDataTable();
        },
        period(){
            //max range 30 days
            this.maxDate =  new Date(this.period[0].getFullYear(), this.period[0].getMonth(), this.period[0].getDate() + 30);
        }
    },
    computed:{
        ...mapGetters(['errors', 'errorEdit', 'errorAdd']),
        period_start_label(){ 
            return moment(this.period[0], 'YYYY-MM-DD').format('YYYY-MM-DD'); 
        },
        period_end_label(){ 
            return moment(this.period[1], 'YYYY-MM-DD').format('YYYY-MM-DD'); 
        },
    },
    methods: {
        formatDate(value){
            if (value) {
                return moment.utc(value).format('DD-MM-YYYY')
            }
        },
        // DATATABLE
        getDataTable(){
            if(
              this.period_start_label !== null && 
              this.period_end_label !== null && 
              this.period_start_label !== 'Invalid date' && 
              this.period_end_label !== 'Invalid date'
            ){  
                this.loading=true;
                this.page = (this.offset / this.rows) + 1;

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/database-start-end',
                    params: {
                        "search" : this.search,
                        "per_page" : this.rows,
                        "page" : this.page,
                        "order_by" : this.field,
                        "sort_by" : this.sort,
                        "start_date" : this.period_start_label,
                        "end_date" : this.period_end_label,
                    }
                })
                .then(res => {
                this.data = res.data.data.data;
                this.dataTable = numberingDatatable(res.data.data.data, this.page, this.rows);
                this.totalItemsCount = res.data.data.total;
                this.rows = parseInt(res.data.data.per_page);
                this.loading=false;
                })
                .catch((err) => {
                    console.log(err);
                });
            }else{
                alert('Pastikan rentang waktu sudah benar atau tidak kosong');
            }
        },
        onSort(event) {
            this.field = event.sortField;
            this.sort = event.sortOrder == '1' ? 'ASC' : 'DESC';
            this.getDataTable();
        },
        // EXPORT
        exportExcelCSV(ext){
            if(
              this.period_start_label !== null && 
              this.period_end_label !== null && 
              this.period_start_label !== 'Invalid date' && 
              this.period_end_label !== 'Invalid date'
            ){
                if(ext == 'xlsx'){
                    this.loadingExcel=true;
                }else if(ext == 'csv'){
                    this.loadingCsv=true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/database-start-end/export',
                    responseType: 'blob',
                    params: {
                        "ext" : ext,
                        "start_date" : this.period_start_label,
                        "end_date" : this.period_end_label,
                    }
                })
                .then(response => {
                    let fileUrl = window.URL.createObjectURL(response.data);
                    let fileLink = document.createElement('a');

                    fileLink.href = fileUrl;

                    fileLink.setAttribute('download', 'Database Start End Export.' + ext);
                    
                    document.body.appendChild(fileLink)

                    fileLink.click();
                    
                    if(ext == 'xlsx'){
                        this.loadingExcel=false;
                    }else if(ext == 'csv'){
                        this.loadingCsv=false;
                    }

                })
                .catch((err) => {
                    console.log(err);
                    
                    if(ext == 'xlsx'){
                        this.loadingExcel=false;
                    }else if(ext == 'csv'){
                        this.loadingCsv=false;
                    }
                });
            }else{
                alert('Pastikan rentang waktu sudah benar atau tidak kosong');
            }
        },
    }
}
</script>